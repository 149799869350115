import './App.css';
import DocumentVerifierForm from './components/DocumentRetriever';
import headerImage from './assets/img/header_bg.png'

function App() {
  return (
    <div className="App">
      <img src={headerImage} alt='banner image' width={'100%'}/>      
      <DocumentVerifierForm/>
    </div>
  );
}

export default App;
