import React, { useState, useEffect } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import { ClockLoader } from 'react-spinners';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import pdf from '../assets/docs/CSTP_20240910100030747321.pdf'

const DocumentRetriever = () => {
  const [selectedOption, setSelectedOption] = useState('');
  const [captchaVerified, setCaptchaVerified] = useState(false);
  const [language, setLanguage] = useState('en');
  const [formData, setFormData] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [isSubmitDisabled, setIsSubmitDisabled] = useState(true);
  const [showSuccess, setShowSuccess] = useState(false);

  const isArabic = language === 'ar';

  useEffect(() => {
    const requiredFields = ['documentReference', 'customerId', ...getRequiredFieldsForOption(selectedOption)];
    const allFieldsFilled = requiredFields.every(field => formData[field] && formData[field].length >= 5);
    // setIsSubmitDisabled(!allFieldsFilled || !captchaVerified);
    setIsSubmitDisabled(!allFieldsFilled);
  }, [formData, captchaVerified, selectedOption]);


  const getRequiredFieldsForOption = (option) => {
    switch (option) {
      case 'Certificate of Balance':
      case 'Mini Account Statement':
      case 'Discharge Letter':
        return ['accountNumber'];
      case 'Account Information Letter':
        return ['accountNumber', 'accountOpenDate'];
      case 'No Objection Letter Personal':
      case 'Balance Certificate for Personal':
      case 'Personal Finance Clearance Letter':
      case 'Home Finance Clearance Letter':
      case 'Balance Certificate for Home':
        return ['financeAccountNumber'];
      case 'Salary Transfer Clearance Letter':
        return ['ibanNumber'];
      case 'Credit Card Clearance Letter':
        return ['creditCardAccountNumber'];
      default:
        return [];
    }
  };

  const handleOptionChange = (event) => {
    setSelectedOption(event.target.value);
    setFormData({});
  };

  const handleCaptchaChange = (value) => {
    setCaptchaVerified(!!value);
  };

  const handleLanguageChange = (lang) => {
    setIsLoading(true);
    setLanguage(lang);
    setTimeout(() => setIsLoading(false), 500);
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData(prev => ({ ...prev, [name]: value }));
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    setIsLoading(true);
    const delay = Math.floor(Math.random() * (4000 - 2000 + 1)) + 2000;
    setTimeout(() => {
      if (
        selectedOption === 'Mini Account Statement' &&
        formData.documentReference === '6VXGWVJHL8YP9731' &&
        formData.customerId === '1009087014' &&
        formData.accountNumber === '611086802045'
      ) {
        successCase();
      } else {
        errorMessage();
      }
      setIsLoading(false);
    }, delay);
  };

  const successCase = () => {
    setShowSuccess(true);
  };

  const errorMessage = () => {
    setIsSubmitDisabled(true);
    toast.error('Error. The mandatory parameter(s) is missing or invalid', {
      position: "top-right",
      autoClose: false,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "colored",
    });
  };

  const handleDownload = () => {
    const link = document.createElement('a');
    link.href = pdf;
    link.download = 'CSTP_20240910100030747321.pdf';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const handleReset = () => {
    setShowSuccess(false);
    setSelectedOption('');
    setFormData({});
    setCaptchaVerified(false);
  };

  const renderDynamicFields = () => {
    switch (selectedOption) {
      case 'Certificate of Balance':
      case 'Mini Account Statement':
      case 'Discharge Letter':
        return renderField(isArabic ? 'رقم الحساب' : 'Account Number', 'accountNumber');
      case 'Account Information Letter':
        return (
          <>
            {renderField(isArabic ? 'رقم الحساب' : 'Account Number', 'accountNumber')}
            {renderField(isArabic ? 'تاريخ فتح الحساب' : 'Account Open Date', 'accountOpenDate', 'date')}
          </>
        );
      case 'No Objection Letter Personal':
      case 'Balance Certificate for Personal':
      case 'Personal Finance Clearance Letter':
      case 'Home Finance Clearance Letter':
      case 'Balance Certificate for Home':
        return renderField(isArabic ? 'رقم حساب التمويل' : 'Finance Account Number', 'financeAccountNumber');
      case 'Salary Transfer Clearance Letter':
        return renderField(isArabic ? 'رقم الآيبان' : 'IBAN Number', 'ibanNumber');
      case 'Credit Card Clearance Letter':
        return renderField(isArabic ? 'رقم حساب البطاقة الأئتمانية' : 'Credit Card Account Number', 'creditCardAccountNumber');
      default:
        return null;
    }
  };

  const renderField = (label, name, type = 'text') => (
    <div className="form-group row mb-2">
      <label className={`col-sm-2 col-form-label p-1 ${isArabic ? 'text-right' : ''}`}>
        {label}
        <span className="asterik">*</span>
      </label>
      <div className="col-sm-4 p-1">
        <input
          type={type}
          name={name}
          className="form-control form-control-sm"
          value={formData[name] || ''}
          onChange={handleInputChange}
        />
      </div>
    </div>
  );

  return (
    <div className={`container ${isArabic ? 'rtl' : ''}`}>
      {isLoading && (
        <div className='d-flex justify-content-center align-items-center' style={{top: 0, left: 0, width: '100%', height: '100%', backgroundColor: 'rgba(51, 51, 51, 0.8)', zIndex: '99999', position: 'absolute', opacity: 1}}>
          <ClockLoader color='white' size={64}/>
        </div>
      )}
      <ToastContainer
        position="top-right"
        autoClose={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        theme="colored"
      />
      <div className="d-flex justify-content-between mt-3">
        <div></div>
        <div className="d-flex">
          <a
            className={`clickable highlight ${language === 'en' ? 'active' : ''}`}
            onClick={() => handleLanguageChange('en')}
          >
            English
          </a>
          <div className="ml-2 mr-2 separator"></div>
          <a
            className={`clickable highlight ${language === 'ar' ? 'active' : ''}`}
            onClick={() => handleLanguageChange('ar')}
          >
            عربي
          </a>
        </div>
      </div>

      <div className="row">
        <div className="p-1 mb-2">
          <h4 className="heading">
            {isArabic ? 'التحقق من خدمة الوثائق البنكية' : 'Verify Bank Documents Service'}
          </h4>
        </div>
      </div>

      {!showSuccess ? (
        <form className="form-group row" noValidate onSubmit={handleSubmit}>
          <div className="col-sm-12">
            <div className="form-group row mb-2">
              <label className={`col-sm-2 col-form-label p-1 ${isArabic ? 'text-right' : ''}`}>
                {isArabic ? 'نوع المستند' : 'Document Type'}
                <span className="asterik">*</span>
              </label>
              <div className="col-sm-4 p-1">
                <select className="form-select form-select-sm" onChange={handleOptionChange}>
                  <option value="">{isArabic ? 'الرجاء اختيار وثيقة رقمية' : 'Please select digital document'}</option>
                  <option value="Certificate of Balance">{isArabic ? 'شهادة رصيد الحساب' : 'Certificate of Balance'}</option>
                  <option value="Account Information Letter">
                    {isArabic ? 'تعريف بمعلومات الحساب' : 'Account Information Letter'}
                  </option>
                  <option value="IBAN Certificate">{isArabic ? 'شهادة تعريف بالآيبان' : 'IBAN Certificate'}</option>
                  <option value="Mini Account Statement">{isArabic ? 'كشف حساب مختصر' : 'Mini Account Statement'}</option>
                  <option value="No Objection Letter Home">
                    {isArabic ? 'خطاب عدم الممانعة للتمويل العقاري' : 'No Objection Letter Home Finance'}
                  </option>
                  <option value="No Objection Letter Personal">
                    {isArabic ? 'خطاب عدم الممانعة للتمويل الشخصي' : 'No Objection Letter Personal Finance'}
                  </option>
                  <option value="Discharge Letter">{isArabic ? 'إخلاء طرف عن الحساب الشخصي' : 'Discharge Letter'}</option>
                  <option value="Balance Certificate for Personal">
                    {isArabic ? 'شهادة إثبات مديونية للتمويل الشخصي' : 'Balance Certificate for Personal Finance'}
                  </option>
                  <option value="Salary Transfer Clearance Letter">
                    {isArabic ? 'خطاب تحويل راتب' : 'Salary Transfer Clearance Letter'}
                  </option>
                  <option value="Personal Finance Clearance Letter">
                    {isArabic ? 'مخالصة عن التمويل الشخصي' : 'Personal Finance Clearance Letter'}
                  </option>
                  <option value="Credit Card Clearance Letter">
                    {isArabic ? 'مخالصة عن البطاقة الائتمانية' : 'Credit Card Clearance Letter'}
                  </option>
                  <option value="Home Finance Clearance Letter">
                    {isArabic ? 'مخالصة عن التمويل العقاري' : 'Home Finance Clearance Letter'}
                  </option>
                  <option value="Balance Certificate for Home">
                    {isArabic ? 'شهادة إثبات مديونية للتمويل العقاري' : 'Balance Certificate for Home Finance'}
                  </option>
                </select>
              </div>
            </div>

            {renderField(
              isArabic ? 'الرقم المرجعي للمستند' : 'Document Reference Number',
              'documentReference'
            )}

            {renderField(
              isArabic ? 'رقم هوية العميل' : 'Customer ID Number',
              'customerId'
            )}

            {renderDynamicFields()}

            <div className="form-group row mb-2">
              <label className={`col-sm-2 col-form-label p-1 ${isArabic ? 'text-right' : ''}`}>
                {isArabic ? 'أدخل كلمة التحقق الأمنية' : 'Enter the security captcha'}
                <span className="asterik">*</span>
              </label>
              <div className="col-sm-4 p-1">
                <ReCAPTCHA sitekey="6LdXzmAqAAAAABqtMYWnlMp6l6HlTM0H6eMT77Yx" onChange={handleCaptchaChange} />
              </div>
            </div>

            <div className="form-group row mb-2">
              <div className="col-2"></div>
              <div className="col-sm-4 p-1 d-flex justify-content-center">
                <button type="submit" className="btn btn-danger" disabled={isSubmitDisabled}>
                  {isArabic ? 'إرسال' : 'Submit'}
                </button>
                <button type="button" className="btn btn-danger mx-1">
                  {isArabic ? 'إلغاء' : 'Cancel'}
                </button>
              </div>
            </div>
          </div>
        </form>
      ) : (
        <div className="text-center">
          <div className="mb-2">
            <iframe src={pdf} width={'100%'} height={'400px'} frameBorder={0}></iframe>
          </div>
          <div>
            {
              isArabic ? (
                <>
                  <button onClick={handleReset} className="btn btn-danger">
                    {isArabic ? 'تحقق من مستند آخر' : 'Verify Another Document'}
                  </button>
                  <button onClick={handleDownload} className="btn btn-danger mx-1">
                    {isArabic ? 'تنزيل المستند' : 'Download Document'}
                  </button>
                </>
              ) : (
                <>
                  <button onClick={handleDownload} className="btn btn-danger mx-1">
                    {isArabic ? 'تنزيل المستند' : 'Download Document'}
                  </button>
                  <button onClick={handleReset} className="btn btn-danger">
                    {isArabic ? 'التحقق من مستند آخر' : 'Verify Another Document'}
                  </button>
                </>
              )
            }
          </div>
        </div>
      )}
    </div>
  );
};

export default DocumentRetriever;